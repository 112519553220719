/**
 *
 * @type {{getInstance: (function(): *)}}
 */
let Template = (function() {
    let instance = undefined;

    return {
        getInstance: function(){
            if(instance === undefined) {
                let oMustache = Mustache;
                oMustache.tags = ['<%', '%>'];

                instance = oMustache;
            }

            return instance;
        }
    };
})();

function reload_page() {
    location.reload();
}

function redirect(url) {
    if(url !== undefined) {
        location.replace(url);
    }
}

function alert_error(text) {
    if (Array.isArray(text)) {
        alert(text.join("\n"));
    } else {
        alert(text);
    }
}

function alert_info(text) {
    alert(text);
}

function axaj_post_request(endpoint, data, success_callback, ajax_return_callback, errors_callback) {
    $.post(endpoint, data, function (raw_json_data) {
        jsdata = jQuery.parseJSON(raw_json_data);

        if (jsdata.status == "success") {
            if (jsdata.message == '')
            {
                success_callback(jsdata);
            } else {
                alert_info(jsdata.message);
            }
        } else {
            if (typeof errors_callback !== "undefined") {
                errors_callback(jsdata.errors);
            } else {
                alert_error(jsdata.errors);
            }
        }
        if (typeof ajax_return_callback !== "undefined") {
            ajax_return_callback(jsdata);
        }
    });
}

function modal(selector, action) {
    $(selector).modal(action);
}

$(function() {

    let currency = $('.cart-total-price').attr('data-currency') || '$';

    //submit #form-singin
    (function() {
        let form = $('#form-singin');
        if(form.length) {
            // submit form
            $(form).submit(function(e){
                let action = $(this).attr('action');
                let data = $(this).serializeArray();
                axaj_post_request(action, data, function(response){
                    let location = response.value.link;
                    if(location !== undefined) {
                        redirect(location);
                    }
                });

                e.preventDefault();
            });

            // forgot password link click
            $('.forgot-link', form).on('click', function(e){
                e.preventDefault();
                let selector = $(this).attr('href');
                if(selector !== undefined) {
                    modal('#signin-modal', 'hide');
                    setTimeout(function(){
                        modal(selector, 'show');
                    }, 500);
                }
            });
        }
    })();

    (function() {
        $(document).on('click', '.btn-wishlist', function (event) {
            event.preventDefault();
            let action;
            const
                button = $(this),
                active = button.hasClass('active'),
                product_id = button.data('product-id');

            if(active) {
                action = button.data('action-delete');
                axaj_post_request(action, {product_id}, function () {
                    button.removeClass('active');
                })
            } else {
                action = button.data('action');
                axaj_post_request(action, {product_id}, function () {
                    button.addClass('active');
                })
            }
        })
    })();

    // #forgot-password
    (function() {
        let form = $('#form-forgot-password');

        if(form.length) {
            // submit form
            $(form).submit(function(e){
                let action = $(this).attr('action');
                let data = $(this).serializeArray();
                axaj_post_request(action, data, function(response){
                    let status = response.status || undefined;
                    if(status !== undefined) {
                        let message = response.value.message || undefined;
                        if(message.length) {
                            alert(message);
                            setTimeout(function(){
                                $('#forgot-password-modal').modal('hide');
                            }, 500);
                        }
                        else {
                            redirect('/');
                        }
                    }
                });

                e.preventDefault();
            });

            // singin link click
            $('.singin-link', form).on('click', function(e){
                e.preventDefault();
                let selector = $(this).attr('href');
                if(selector !== undefined) {
                    modal('#forgot-password-modal', 'hide');
                    setTimeout(function(){
                        modal(selector, 'show');
                    }, 500);
                }
            });
        }
    })();

    //cart preview
    (function() {
        let action = '/ajax/cart/get_items/';
        let data = {};
        axaj_post_request(action, data, function(response) {
            var event = new CustomEvent("CART_ITEMS", {
                detail: response
            });
            document.dispatchEvent(event);
            let products = response.value.products || [];
            let templateData = {
                products: []
            };
            if(products.length) {
                templateData.products = products.map(function(value){
                    let data = {};
                    data.id = value.id;
                    data.link = value.link;
                    try {
                        data.img = value.image['60x60'];
                    } catch (e) {
                        data.img = '/common/img/380x380.png';
                    }
                    data.title = value.title;
                    data.dynamic_price = value.dynamic_price;
                    data.qty = value.qty;
                    data.summ = value.summ;
                    return data;
                });
            }
            let template = document.getElementById('template-cart-product-list-preview').innerHTML;
            let html = Template.getInstance().render(template, templateData);

            $('.dropdown-cart-products').html(html);
            $('.cart-total-price').html(currency + response.value.sub_price);
        });

        $('.dropdown-cart-products').on('click', '.btn-remove', function(e){
            let wrapper = $(this).parents('.product');
            let productId = 0;
            let endpointToDelete = undefined;
            if(wrapper !== undefined) {
                productId = $(wrapper).data('id') || 0;
                endpointToDelete = $(wrapper).data('endpoint_delete_product') || undefined;
            }
            if(endpointToDelete !== undefined) {
                let data = {
                    id: productId,
                };
                axaj_post_request(endpointToDelete, data, function(response){
                    $(document).trigger('CART_PRODUCT:PRODUCT_DELETED', [response, productId]);

                    $(wrapper).hide();

                    $('.cart-count').html(response.value.products.length);
                    $('.cart-total-price').html(currency +response.value.sub_price);
                });
            }
        });
    })();

    //custom events
    //add new product data to cart popup
    $(document).on('PRODUCT_ADD_TO_CART', function(e, response, id){
        let cart = $('.dropdown-cart-products');
        let productData = null;
        if(id) {
            for (let i=0; i<response.value.products.length || 0; i++) {
                if(response.value.products[i].id == id) {
                    productData = response.value.products[i];
                }
            }
        }

        let productWrapper = $(cart).find('.product[data-id="'+id+'"]');
        if(productWrapper.length) {
            // update count for product
            let count = productData.qty || 0;
            $('.cart-product-qty', productWrapper).html(count);
        }
        else {
            // add new product to cart popup
            let templateData = {
                id: productData.id || 0,
                title: productData.title || '',
                link: productData.link || '',
                img: productData.image['60x60'] || 'https://via.placeholder.com/60',
                qty: productData.qty || 0,
                dynamic_price: productData.dynamic_price || 0,
            };
            let template = document.getElementById('template-cart-product-preview').innerHTML;
            let html = Template.getInstance().render(template, templateData);
            $(cart).append(html);
        }

        $('.cart-count').html(response.value.products.length);
        $('.cart-total-price').html(currency + response.value.sub_price);
    });
    $(document).on('CART_PRODUCT:PRODUCT_UPDATED', function(e, response, id){
        let cart = $('.dropdown-cart-products');
        let productData = null;
        if(id) {
            for (let i=0; i<response.value.products.length || 0; i++) {
                if(response.value.products[i].id == id) {
                    productData = response.value.products[i];
                }
            }
        }

        let productWrapper = $(cart).find('.product[data-id="'+id+'"]');
        if(productWrapper.length) {
            // update count for product
            let count = productData.qty || 0;
            $('.cart-product-qty', productWrapper).html(count);
        }

        $('.cart-total-price').html(currency + response.value.sub_price || 0);
    });
    $(document).on('CART_PRODUCT:PRODUCT_DELETED', function(e, response, id){
        let cart = $('.dropdown-cart-products');
        let productWrapper = $(cart).find('.product[data-id="'+id+'"]');
        if(productWrapper.length) {
            $(productWrapper).hide();
        }

        $('.cart-total-price').html(currency + response.value.sub_price || 0);
    });

    // customization sliders
    (function(){
        let styles = [];
        let slides = $('div[data-img_mobile]');
        if(slides.length) {
            slides.each(function (i, node) {
                let image = $(node).data('img_mobile');
                let id = $(node).data('id');
                if( id != undefined && image != undefined && image.length) {
                    let style = 'div[data-id="'+id+'"] { background-image: url("'+image+'") !important; }';
                    try {
                        document.styleSheets[0].insertRule("@media only screen and (max-width : 767px) { "+style+" }","");
                        styles.push(style);
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    })();

    // lazy load
    $('.img-lazy').Lazy({
        placeholder: "data:image/gif;base64,R0lGODlhEALAPQAPzl5uLr9Nrl8e7..."
    });
});
