function CartProduct(settings) {
    this.wrapper = settings.wrapper || undefined;

    // data
    this.order_id = settings.order_id || 0;
    this.order_payed = settings.order_payed || 0;
    this.id = settings.id || undefined;
    this.variation_code = settings.variation_code || undefined;
    this.endpoint_update_product_qty = settings.endpoint_update_product_qty || undefined;
    this.endpoint_delete_product = settings.endpoint_delete_product || undefined;

    // input
    this.inputCount = settings.inputCount || undefined;

    // text
    this.textToatalPriceWrapper = settings.textToatalPriceWrapper || undefined;
    this.textTptalPricePrefix = settings.textTptalPricePrefix || undefined;

    // buttons
    this.btnDelete = settings.btnDelete || undefined;

    this.binding();
}

CartProduct.prototype.binding = function() {
    let that = this;

    // click on delete
    if(that.btnDelete !== undefined) {
        $(that.btnDelete).on('click', function(e) {
            if(that.endpoint_delete_product !== undefined) {
                let data = {
                    order_id: that.order_id,
                    id: that.id,
                    product_variation: that.variation_code
                };
                axaj_post_request(that.endpoint_delete_product, data, function(response){
                    that.productDeleted(response, that.id)
                });
            }
        });
    }

    // change amount
    if(that.inputCount !== undefined) {
        $(that.inputCount).bind('change', function(e) {
            let value = $(this).val();
            if(that.endpoint_update_product_qty !== undefined) {
                // send request
                let data = {
                    order_id: that.order_id,
                    id: that.id,
                    amount: value,
                    product_variation: that.variation_code
                };
                axaj_post_request(that.endpoint_update_product_qty, data, function(response){
                    that.productUpdated(response)
                });
            }
        });
    }
};

CartProduct.prototype.productUpdated = function(response) {
    let that = this;
    let productResponseData = response.value.products.find(function(product){
        if(product.id == that.id) {
            return true;
        }
        return false;
    });

    if(productResponseData) {
        let productId = productResponseData.id || 0;
        let summ = parseFloat(productResponseData.summ).toFixed(1);

        $(that.textToatalPriceWrapper).text(that.textTptalPricePrefix + summ);

        let maxCount = parseInt(productResponseData.count) || undefined;
        if(maxCount !== undefined && maxCount >= 0) {
            let qty  = parseInt(productResponseData.qty) || undefined;
            if(qty !== undefined) {
                if(qty >= maxCount) {
                    that.inputCount.val(maxCount);
                }
            }
        }

        $(document).trigger('CART_PRODUCT:PRODUCT_UPDATED', [response, productId]);
    }
};

CartProduct.prototype.productDeleted = function(response, productId) {
    $(this.wrapper).hide();
    $(document).trigger('CART_PRODUCT:PRODUCT_DELETED', [response, productId]);
};