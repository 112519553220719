function getCatalogSlug() {
    if($('meta[property="catalog_slug"]')) {

        return $('meta[property="catalog_slug"]')[0].content;
    }

    return '';
}

function Product(settings) {
    this.wrapper = settings.wrapper || undefined;
    var that = this;

    // data
    this.isProductPage = settings.isProductPage || false;
    this.id = settings.id || undefined;
    this.price = settings.price || undefined;
    this.count = settings.count || undefined;

    this.endpointAddToCart = settings.endpointAddToCart || undefined;

    this.variationValueSelector = settings.variationValueSelector || undefined;
    this.variationSelector = settings.variationSelector || undefined;
    this.variations = settings.variations || undefined;

    // input
    this.inputPrice = settings.inputPrice || undefined;
    this.inputCount = settings.inputCount || undefined;

    // text
    this.textTitleWrapper = settings.textTitleWrapper || undefined;
    this.textPriceWrapper = settings.textPriceWrapper || undefined;

    // buttons
    this.btnCart = settings.btnCart || undefined;

    this.tools = {
        maxRuns: 10,
        currentRuns: 0,
        htmlReady: false,
        setVariationSelectionFromHash: function (that){
            var hashParams =  that.tools.getParamsFromHash();
            $.each(hashParams, function (key, value) {
                var select = $('select[name="' + key + '"]');
                if(select.length > 0){
                   that.tools.htmlReady = true;
                }
                $(select).selectpicker('val', value);
                $(select).selectpicker('refresh');
            });

            that.tools.currentRuns++;

            if ((that.tools.maxRuns > that.tools.currentRuns) && that.tools.htmlReady !== true) {
                setTimeout(function (){
                    that.tools.setVariationSelectionFromHash(that);
                }, 300);
            }
        },
        objectJoin(obj, glue, separator) {
            if (glue == undefined)
                glue = '=';

            if (separator == undefined)
                separator = ',';

            return Object.keys(obj).map(function (key) {
                return [key, obj[key]].join(glue);
            }).join(separator);
        },
        setUrlHash: function (params) {
            var paramObj = {};
            params.forEach(function (param){
                paramObj[param['code'].toString()] = param['value'];
            });

            window.location.hash = that.tools.objectJoin(paramObj,'=','&');
        },
        getParamsFromHash: function(){
            var hash = window.location.hash.substring(1);
            return hash.split('&').reduce(function (res, item) {
                var parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});
        }
    }

    this.tools.setVariationSelectionFromHash(this);

    this.binding();

    if(true === settings.initVariationsBinding){
        this.variationsBinding();
    }
}

Product.prototype.variationsBinding = function() {
    let that = this;
    document.addEventListener('GOT_VALID_VARIATION_URL', function (e) {
        let data = e.detail;
        if(that.variationValueSelector) {
            if(data.hasOwnProperty('variation_code')) {
                $(that.variationValueSelector).val(data['variation_code']);
            }
        }

        if(data.hasOwnProperty('url') && data.url)
        {
            $('#variations-holder').addClass('disabled');
            if(that.variationSelector) {
                $(that.variationSelector).attr('disabled', true).selectpicker('refresh');
            }
            if(data.catalog_slug){
                data.url = data.url + '&catalog_slug=' + data.catalog_slug;
            }

            $.ajax('/ajax' + data.url, {
                dataType: 'json',
                success: function (data) {
                    if (typeof data === 'object' && data.hasOwnProperty('status') && data.status === 'success') {
                              window.location.hash = '';
                      return  window.location.pathname = data.link;
                    }
                    $('#product-data-holder').html(data.render);
                    if(that.variationSelector) {
                        $(that.variationSelector).removeAttr('disabled').selectpicker('refresh');
                    }
                    if(window.initPageProduct) {
                        window.initPageProduct(true);
                    }
                    if ( $.fn.inputSpinner ) {
                        $("#product-data-holder input[type='number']").inputSpinner({
                            decrementButton: '<i class="icon-minus"></i>',
                            incrementButton: '<i class="icon-plus"></i>',
                            groupClass: 'input-spinner',
                            buttonsClass: 'btn-spinner',
                            buttonsWidth: '26px'
                        });
                    }
                    if(window.initCarousels) {
                        window.initCarousels($('#product-data-holder'));
                    }
                    if(window.initProductGallery) {
                        window.initProductGallery();
                    }
                    document.dispatchEvent(new CustomEvent('PRODUCT_VARIATION_RELOADED', { detail: data.product.id }));
                },
                error: function () {
                    $('#variations-holder').removeClass('disabled');
                }
            })
        }
        else {

             if(data.hasOwnProperty('foundVariation') && data.foundVariation){
                that.tools.setUrlHash(data.foundVariation);
            }

            console.log(['Error', data]);
            if(that.variationSelector) {
                $(that.variationSelector).attr('disabled', false).selectpicker('refresh');
            }
        }
    });
}

Product.prototype.binding = function() {
    let that = this;

    // to cart
    $(that.btnCart).on('click', function(e){
        e.preventDefault();

        if(that.id) {
            let count = that.getCount();
            let variationCode = '';
            let variationData = []
            if(that.variationValueSelector !== undefined) {
                variationCode = $(that.variationValueSelector).val();
                variationData = that.variations[variationCode] || [];

                // chech variation before adding to cart
                let validVariation = true;
                for(let i = 0; i < variationData.length; i++) {
                    let data  = variationData[i];

                    let code  = data['code']  || undefined;
                    let value = data['value'] || undefined;

                    let fieldValue = $('.variation-selector[name="'+code+'"]').val();

                    if(value != fieldValue) {
                        validVariation = false;
                        break;
                    }
                }
                if(!validVariation) {
                    let eventData = {
                        id: that.id,
                        variationCode: variationCode,
                        variationData: variationData
                    };
                    let event = new CustomEvent('INVALID_VARIATION', {'detail': eventData});
                    document.dispatchEvent(event);
                    return false;
                }
            }

            if(count > 0) {
                if (that.endpointAddToCart !== undefined) {
                    let data = {
                        'id': that.id,
                        'amount': count,
                        'variation_code': variationCode,
                        'variation_data': variationData
                    };
                    axaj_post_request(that.endpointAddToCart, data, function(response){
                        $(document).trigger('PRODUCT_ADD_TO_CART', [response, that.id]);

                        let eventData = {
                            id: that.id,
                            response: response
                        };
                        let event = new CustomEvent('PRODUCT_ADDED_TO_CART', {'detail': eventData});
                        document.dispatchEvent(event);
                    });
                }
                else {
                    // TODO empty endpoint
                }
            }
            else {
                // TODO count is 0
            }
        }
        else {
            // TODO product with out id
        }
    });
    if(that.variationSelector !== undefined) {
        $(document).on('change', that.variationSelector,function(e){
            if(!$(this).is('select') || $(this).is('.disabled') || $(this).closest('.disabled').length) {
                return;
            }

            let currentVariations = that.getCurrentVariations();
            let foundVariation = false;
            let foundVariationCode = that.searchCurrentVariationCodeInVariationList(currentVariations);

            if(foundVariationCode) {
                if(that.variations.hasOwnProperty(foundVariationCode)) {
                    foundVariation = that.variations[foundVariationCode];
                }
            }

            if(foundVariation) {
                let variationName = $(this).attr('name');
                let variationValue = null;
                for(let i=0; i<foundVariation.length; i++) {
                    if(variationName === foundVariation[i]['code']) {
                        variationValue = foundVariation[i];
                    }
                }
                if(variationValue != null) {
                    let variationUrl = that.generateVariationUrl(variationValue, foundVariation);
                    let eventDetails = {
                        'url': variationUrl,
                        'variation_code': foundVariationCode,
                        'catalog_slug' : getCatalogSlug(),
                        'foundVariation': foundVariation
                    };
                    let event = new CustomEvent('GOT_VALID_VARIATION_URL', {'detail': eventDetails});
                    document.dispatchEvent(event);
                }
                else {
                    let event = new Event('NOT_FOUND_VARIATION');
                    document.dispatchEvent(event);
                }
            }
            else {
                let currentValue = e.target.value;
                let eventDetails = {
                    field: this,
                    value: currentValue
                };
                let event = new CustomEvent('NOT_FOUND_VARIATION', {'detail': eventDetails});
                document.dispatchEvent(event);
            }
        });
    }

    document.addEventListener('NOT_FOUND_VARIATION', function(e) {
        (function(e){
            let eventData = e.detail || undefined;
            if(eventData == undefined) {
                return false;
            }

            let selectedVariationCode = $(eventData.field).attr('name') || undefined;
            let selectedVariationName = eventData.value || undefined;
            let avalibaleValues = that.findVariationsAvalibaleValues(selectedVariationCode, selectedVariationName);
            let selectedVariationId   = $(eventData.field).attr('id') || undefined;
            let fields = $('.variation-selector');
            fields.each(function(i, element) {
                let id = $(element).attr('id');
                if(selectedVariationId != id) {
                    let selectedFieldValue = $(':selected', element).val();
                    let selectedFieldName  = $(element).attr('name');
                    // reset field data
                    // check field data
                    if(avalibaleValues.hasOwnProperty(selectedFieldName)) {
                        if(!avalibaleValues[selectedFieldName].includes(selectedFieldValue)) {
                            $(element).selectpicker('val', null);
                        }
                    }
                }
            });
        })(e);
    });
    document.addEventListener('INVALID_VARIATION', function(e) {
        let eventData = e.detail || undefined;
        if(eventData == undefined) {
            return false;
        }
        alert('Invalid data');
    });
};

Product.prototype.getCount = function() {
    let that = this;
    let count = 0;

    if(that.inputCount !== undefined) {
        count = parseInt($(that.inputCount).val());
    }
    else {
        if(that.count !== undefined) {
            count = that.count;
        }
    }

    return count;
};

Product.prototype.getCurrentVariations = function() {
    let that = this;
    let variations = [];

    if(that.variationSelector !== undefined) {
        $(that.variationSelector).each(function(i, el) {
            let code = $(this).attr('name');
            let value = $(this).val();
            if(value == '#') {
                value = null;
            }
            if(code != undefined && value != "") {
                let data = {
                    key: code,
                    value: value
                };
                variations.push(data);
            }
        });
    }

    return variations;
};

Product.prototype.searchCurrentVariationCodeInVariationList = function(currentVariations) {
    let that = this;
    let result = null;

    let isHasRow = function(currentVariation, variations) {
        result = false;

        let code  = currentVariation['key'];
        let value = currentVariation['value'];

        for (let i=0; i<variations.length; i++) {
            if(code == variations[i]['code'] && value == variations[i]['value']) {
                result = true;
                break;
            }
        }

        return result;
    };

    let isEqu = function(currentVariations, variations) {
        let result = true;

        if(currentVariations.length == variations.length) {
            for (let i=0; i<currentVariations.length; i++) {
                let hasRow = isHasRow(currentVariations[i], variations);
                if(!hasRow) {
                    result = false;
                    break;
                }
            }
        }
        else {
            result = false;
        }

        return result;
    };

    let foundVariationCode = null;
    if(that.variations != undefined) {
        let variations = that.variations;
        for (var variation_code in variations) {
            if(variations.hasOwnProperty(variation_code )) {
                if(isEqu(currentVariations, variations[variation_code])) {
                    foundVariationCode = variation_code;
                }
            }
        }
    }

    if(foundVariationCode != null) {
        result = foundVariationCode;
    }

    return result;
};

Product.prototype.variationUrl = function(productSlug, variationUrlParams) {
    return '/product/'+productSlug+'/'+'?'+variationUrlParams;
};

Product.prototype.generateVariationUrl = function(variationValue, foundVariation) {
    let that = this;
    let url  = undefined;

    if(!variationValue.hasOwnProperty('generated')) {

        let productSlug = 'v--'+variationValue['product_id'];
        let params = [];
        for(let i=0; i<foundVariation.length; i++) {
            params.push('variation['+foundVariation[i]['code']+']='+foundVariation[i]['value']);
        }

        url = that.variationUrl(productSlug, params.join('&'));
    }

    return url;
};

Product.prototype.findVariationsAvalibaleValues = function(variationName, variationValue) {
    let that = this;
    let avalibaleValues = {};
    let variations = that.variations;

    for (var variation_code in variations) {
        if(variations.hasOwnProperty(variation_code )) {
            let values = {};
            let isFind = false;
            variations[variation_code].forEach(function(data) {
                let code  = data.code  || undefined;
                let value = data.value || undefined;

                if(code == variationName && value == variationValue) {
                    isFind = true;
                }

                if(!values.hasOwnProperty(code)) {
                    values[code] = [];
                    values[code].push(value);
                }
            });
            if(isFind) {
                for(let code in values) {
                    if(!avalibaleValues.hasOwnProperty(code)) {
                        avalibaleValues[code] = [];
                    }
                    avalibaleValues[code] = avalibaleValues[code].concat(values[code])
                }
            }
        }
    }

    return avalibaleValues;
};


