function CartShippingFlyUpdater(settings, response) {
    this.pricePrefix = '$';
    this.update(response);
}
CartShippingFlyUpdater.prototype.update  = function(response) {
    const self = this;
    const shippingMethods = response['value']['shipping_methods'] || undefined;
    if(shippingMethods == undefined) {
        return false;
    }

    shippingMethods.forEach(function(methodData) {
        const selector = '#' + 'shipping-price-' + (methodData['id'] || 0);
        const elMethodPrice = $(selector);
        console.log(elMethodPrice);
        if($(elMethodPrice).length) {
            $(elMethodPrice).text(self.pricePrefix+methodData['price']);
        }
    });
};
