function CartShipping(settings) {

    this.order_id = settings.order_id || 0;
    this.endpoint_update_shipping_method = settings.endpoint_update_shipping_method || undefined;
    this.inputShipping = settings.inputShipping || undefined;

    this.binding();
}

CartShipping.prototype.binding = function() {
    let that = this;

    // change shipping metod
    if(this.inputShipping !== undefined) {
        $(this.inputShipping).change(function(e) {
            let shippingMethodId = parseInt($(this).val());
            if(isNaN(shippingMethodId)) {
                shippingMethodId = 0;
            }
            if(that.endpoint_update_shipping_method !== undefined) {
                let data = {
                    order_id: that.order_id,
                    shipping_method_id: shippingMethodId,
                };
                axaj_post_request(that.endpoint_update_shipping_method, data, function(response){
                    that.cartUpdate(response);
                });
            }
        });
    }
};

CartShipping.prototype.cartUpdate = function(response) {
    $(document).trigger('CART_SHIPPING:CHANGED_SHIPPING_METOD', [response]);
};