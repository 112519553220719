function Cart(settings) {

    this.order_id = settings.order_id || 0;
    this.order_payed = settings.order_payed || 0;
    this.wrapper = settings.wrapper || undefined;
    this.endpoint_update_shipping_method = settings.endpoint_update_shipping_method;
    this.endpoint_update_order = settings.endpoint_update_order;

    // text
    this.textSubtotalPriceWrapper = settings.textSubtotalPriceWrapper || undefined;
    this.textSubtotalPricePrefix = settings.textSubtotalPricePrefix || '';
    this.textTotalPriceWrapper = settings.textTotalPriceWrapper || undefined;
    this.textTotalPricePrefix = settings.textTotalPricePrefix || '';
    this.textCountItems = settings.textCountItems || undefined;

    // input
    this.inputShipping = settings.inputShipping || undefined;

    this.init();
    this.binding();
}

Cart.prototype.init = function() {
    let that = this;
    let settings = {
        'order_id': that.order_id,
        'inputShipping': that.inputShipping,
        'endpoint_update_shipping_method': that.endpoint_update_shipping_method
    };
    new CartShipping(settings);
};

Cart.prototype.binding = function() {
    let that = this;

    // custom events
    $(document).on('CART_PRODUCT:PRODUCT_UPDATED CART_PRODUCT:PRODUCT_DELETED CART_SHIPPING:CHANGED_SHIPPING_METOD', function(e, data) {
        let subTotal = parseFloat(data.value.sub_price || 0).toFixed(1);
        let total = parseFloat(data.value.total_price || 0).toFixed(1);

        $(that.textSubtotalPriceWrapper).text(that.textSubtotalPricePrefix + subTotal);
        $(that.textTotalPriceWrapper).text(that.textTotalPricePrefix + total);
        $(that.textCountItems).text(data.value.count);

        if(data.value.count == 0) {
            reload_page();
        }
    });
};